import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../auth/authContext";

export const PublicRoute = ({children}) => {
    const {auth} = useAuth();
    const { isAuthenticated } = auth;

    if(isAuthenticated){
        return (
            <Navigate to='/' />
        );
    }

    return children;
}

export default PublicRoute;