import axios from 'axios';
import React from 'react';
import { useAuth } from '../auth/authContext';

const API_BASE = process.env.REACT_APP_BACKEND_URL;

const useHttp = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const { auth } = useAuth();

    const defaultHeaders = {
        Authorization: `Bearer ${auth.jwt}`
    };

    const post = async (url, data) => {
        try {
            const response = await axios.post(`${API_BASE}/${url}`, {
                ...data
            });
            return response;
        } catch (error) {
            throw error;
        }
    };

    const get = async (url, options = {}) => {

        const headers = {
            ...defaultHeaders,
            ...options.headers,
        };

        try {
            const response = await axios.get(`${API_BASE}/${url}`, {
                headers
            });
            return response?.data;
        } catch (error) {
            throw error;
        }
    };

    const put = async (url, data) => {
        try {
            const response = await axios.put(`${API_BASE}/${url}`, {
                ...data
            });
            return response;
        } catch (error) {
            throw error;
        }
    };

    const patch = async (url, data) => {
        try {
            setIsLoading(true);
            const response = await axios.patch(`${API_BASE}/${url}`, {
                ...data
            });
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const destroy = async (url, options = {}) => {

        const headers = {
            ...defaultHeaders,
            ...options.headers,
        };

        try {
            setIsLoading(true);
            await axios.delete(`${API_BASE}/${url}`, {
                headers
            });
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    return {
        isLoading,
        post,
        get,
        put,
        destroy,
        patch
    };
};

export default useHttp;