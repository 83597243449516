import axios from "axios";
import React, { useState } from "react";
import httpReducer from "./httpReducer";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const initialHttpState = {
  isLoading: false,
  error: "",
};

export const HttpContext = React.createContext(null);

export function HttpProvider(props) {
  const [http, dispatch] = React.useReducer(httpReducer, initialHttpState);
  const ctx = { http, dispatch };
  return (
    <HttpContext.Provider value={ctx}>{props.children}</HttpContext.Provider>
  );
}

export const usePost = () => {
  const { dispatch } = useHttp();
  const [isLoading, setIsloading] = useState(false);
  const post = async (endpoint, data) => {
    setIsloading(true);
    dispatch({ type: "onRequest" });
    try {
      const result = await axios.post(`${apiUrl}/api/${endpoint}`, data);
      dispatch({ type: "onResponseSuccess" });
      setIsloading(false);
      return result.data ?? result;
    } catch (err) {
      dispatch({ type: "onResponseError" });
      setIsloading(false);
    }
  };
  return { post, isLoading };
};

export const useGet = () => {
  const { dispatch } = useHttp();
  const get = async (endpoint, query) => {
    dispatch({ type: "onRequest" });
    try {
      const receive = await axios.get(
        `${apiUrl}/api/${endpoint}${query && `?${query}`}`
      );
      dispatch({ type: "onResponseSuccess" });
      return receive.data.data ?? receive;
    } catch {
      dispatch({ type: "onResponseError" });
    }
  };
  return { get };
};

export const useHttp = () => {
  const context = React.useContext(HttpContext);
  return context;
};
