import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../auth/authContext";

export const PrivateRoute = ({children}) => {
    const { auth } = useAuth();
    const { isAuthenticated } = auth;

    if(isAuthenticated){
        return children;
    }

    return (
        <Navigate to='/login' /> 
    );
}

export default PrivateRoute;