import axios from 'axios';
import React from 'react';
import authReducer, { initialAuthDefault } from './authReducer';
import setupAxiosInterceptors from './axiosInterceptor';

export const AuthContext = React.createContext(null);

let initialAuth;
try {
    initialAuth = JSON.parse(localStorage.getItem('upsioAuth')) ?? initialAuthDefault;
} catch {
    console.error('error parsing auth object from LS');
    initialAuth = initialAuthDefault;
}

export function AuthProvider(props) {
    const [auth, dispatch] = React.useReducer(authReducer, initialAuth);

    React.useEffect(() => {
        localStorage.setItem('upsioAuth', JSON.stringify(auth));
        axios.defaults.headers.common['Authorization'] = `Bearer ${auth.jwt}`;
        setupAxiosInterceptors(dispatch);
    }, [auth]);

    React.useEffect(() => {
        const checkTokenExpiration = () => {
            const tokenExpiry = auth.expiresAt;

            if (tokenExpiry && Date.now() > tokenExpiry) {
                // Token has expired, log the user out
                dispatch({ type: 'logout' });
            } else if (!tokenExpiry && auth.isAuthenticated) {
                // No expiry date, but user is authenticated, log the user out
                dispatch({ type: 'logout' });
            }
        };

        checkTokenExpiration(); // Check on component mount

        const interval = setInterval(checkTokenExpiration, 60000); // Check every minute

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [auth]);

    const ctx = { auth, dispatch };

    return (
        <AuthContext.Provider value={ctx}>
            {props.children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = React.useContext(AuthContext);
    return context;
}
