// src/hooks/usePushSubscription.js
import { useEffect, useState } from 'react';
import useHttp from '../hooks/useHttp';

const usePushSubscription = (isAuthenticated, isAdmin) => {
    const { post } = useHttp();  // The post function from useHttp
    const [subscriptionSent, setSubscriptionSent] = useState(false);  // Flag to track if subscription is sent

    useEffect(() => {

        if (!isAuthenticated) return;

        if ('serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker.register('/service-worker.js')
                .then(registration => {
                    console.log('Service Worker registered:', registration);

                    // Request permission for push notifications
                    Notification.requestPermission().then(permission => {
                        if (permission === 'granted') {
                            registration.pushManager.getSubscription().then(subscription => {
                                // Only proceed if no subscription exists and the request hasn't been sent yet
                                if (!subscription && !subscriptionSent) {
                                    registration.pushManager.subscribe({
                                        userVisibleOnly: true,
                                        applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY
                                    }).then(newSubscription => {
                                        console.log('Push subscription successful:', newSubscription);
                                        if (isAuthenticated) {
                                            // Send the subscription to the backend for storage
                                            post('api/push-subscription', {
                                                newSubscription
                                            }).then(response => {
                                                console.log('Subscription saved:', response);
                                                setSubscriptionSent(true);  // Set flag to true once subscription is sent
                                            }).catch(error => {
                                                console.error('Error saving subscription:', error);
                                            });
                                        } else {
                                            console.error('User is not authenticated');
                                        }
                                    }).catch(err => {
                                        console.error('Push subscription failed:', err);
                                    });
                                }
                            });
                        }
                    });
                }).catch(error => {
                    console.error('Service Worker registration failed:', error);
                });
        }
    }, [isAuthenticated, isAdmin, subscriptionSent]);  // Added subscriptionSent flag to dependencies

};

export default usePushSubscription;