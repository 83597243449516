import styles from './loading.module.scss';
import React from 'react';
import BrandIcon from '../brand/brandIcon';

export  default function Loading() {
    return (
        <section className={styles.loading}>
            <div className={styles.sprite} aria-label="Loading...">
                <div className={styles.circle}>
                    <svg className={styles.svg} viewBox="0 0 42 42">
                        <circle className={styles.svgCircle} cx="21" cy="21" r="15.91549430918952" fill="none" />
                    </svg>
                </div>
                <div className={styles.icon}>
                    <BrandIcon />
                </div>
            </div>
        </section>
    )
};