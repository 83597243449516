import React from "react";
import styles from "./brand.module.scss";

export default function BrandIcon({ color }) {
    return (
        <svg
            className={
                styles.brandIcon + (color ? ` ${styles[`${color}`]}` : "")
            }
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 1000"
        >
            <path
                className={styles.iconPath}
                d="M923.85,679.05C947.13,624.02,960,563.51,960,500c0-254.05-205.95-460-460-460v920C245.95,960,40,754.05,40,500c0-123.75,48.87-236.09,128.36-318.77"
            />
        </svg>
    );
}
