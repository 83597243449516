import { useAuth } from "../../auth/authContext";

export const RequiresAdmin = ({children}) => {
    const { auth } = useAuth();
    const { isAuthenticated } = auth;
    if(auth.isAdmin & isAuthenticated){
        return children;
    }

    return;
}

export default RequiresAdmin;