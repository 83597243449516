// https://www.ios-resolution.com/

import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const rootDomain = process.env.REACT_APP_FRONTEND_URL;

const LaunchScreens = () => (
    <HelmetProvider>
        <Helmet>

            {/* iPhone 1st Gen (320px × 480px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_320x480@2x_p.png`}
                media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_320x480@2x_l.png`}
                media="(device-width: 480px) and (device-height: 320px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />

            {/* iPhone SE 1st Gen (320px × 568px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_320x568@2x_p.png`}
                media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_320x568@2x_l.png`}
                media="(device-width: 568px) and (device-height: 320px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />

            {/* iPhone SE 2nd and 3rd Gen (375px × 667px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_375x667@2x_p.png`}
                media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_375x667@2x_l.png`}
                media="(device-width: 667px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />

            {/* iPhone X / XS / 11 Pro (375px × 812px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_375x812@3x_p.png`}
                media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_375x812@3x_l.png`}
                media="(device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />

            {/* iPhone 12, 12 Pro, 13, 13 Pro (390px × 844px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_390x844@3x_p.png`}
                media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_390x844@3x_l.png`}
                media="(device-width: 844px) and (device-height: 390px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />

            {/* iPhone 14 Pro, 15, 15 Pro, 16 (393px × 852px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_393x852@3x_p.png`}
                media="(device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_393x852@3x_l.png`}
                media="(device-width: 852px) and (device-height: 393px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />

            {/* iPhone 16 Pro (402px × 874px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_402x874@3x_p.png`}
                media="(device-width: 402px) and (device-height: 874px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_402x874@3x_l.png`}
                media="(device-width: 874px) and (device-height: 402px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />

            {/* iPhone 6 Plus, 7 Plus, 8 Plus (414px × 736px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_414x736@3x_p.png`}
                media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_414x736@3x_l.png`}
                media="(device-width: 736px) and (device-height: 414px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />

            {/* iPhone 11, XR (414px × 896px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_414x896@2x_p.png`}
                media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_414x896@2x_l.png`}
                media="(device-width: 896px) and (device-height: 414px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />

            {/* iPhone 11 Pro Max / XS Max (414px × 896px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_414x896@3x_p.png`}
                media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_414x896@3x_l.png`}
                media="(device-width: 896px) and (device-height: 414px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />

            {/* iPhone 12 Pro Max, 13 Pro Max, 14 Plus (428px × 926px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_428x926@3x_p.png`}
                media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_428x926@3x_l.png`}
                media="(device-width: 926px) and (device-height: 428px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />

            {/* iPhone 14 Pro Max, 15 Plus, 15 Pro Max, 16 Plus (430px × 932px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_430x932@3x_p.png`}
                media="(device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_430x932@3x_l.png`}
                media="(device-width: 932px) and (device-height: 430px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />

            {/* iPhone 16 Pro Max (440px × 956px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_440x956@3x_p.png`}
                media="(device-width: 440px) and (device-height: 956px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_440x956@3x_l.png`}
                media="(device-width: 956px) and (device-height: 440px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
            />






            {/* iPad Mini (768px × 1024px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_1536x2048@2x.png`}
                media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_1536x2048@2x_landscape.png`}
                media="(device-width: 1024px) and (device-height: 768px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />

            {/* iPad Pro 12.9" 6th generation (1024px × 1366px) */}
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_2048x2732@2x.png`}
                media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
                rel="apple-touch-startup-image"
                href={`${rootDomain}/launchscreens/launchscreen_2048x2732@2x_landscape.png`}
                media="(device-width: 1366px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
            />


        </Helmet>
    </HelmetProvider>
);

export default LaunchScreens;