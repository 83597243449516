export default function httpReducer(state, action){
    switch (action.type){
        case "onRequest": {
            return {
                ...state,
                isLoading: true
            };
        }
        case "onResponseSuccess": {
            return {
                ...state,
                isLoading: false,
                error: ''
            }
        }
        case "onResponseError": {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        default: throw new Error(`Invalid reducer type: ${action.type}`);
    }
}